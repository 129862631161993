export const API_VERSION = "/api/v1";

export const HOST = process.env.REACT_APP_ENV_DOMAIN + API_VERSION;
// export const HOST = process.env.REACT_APP_ENV_DOMAIN; //本地配置

export const LOGIN_URL = HOST + '/user/login'
export const REGISTER_URL = HOST + '/user/register'
export const USER_URL = HOST + '/user/'
export const USER_NAME_URL = HOST + '/user/name'
export const USER_LIST_URL = HOST + '/user'
export const USER_UNREAD_LIST_URL = HOST + '/user-unread'

export const USER_FRIEND_URL = HOST + '/friend'

export const MESSAGE_URL = HOST + '/message'
export const LAST_MESSAGE_URL = HOST + '/last-message'
export const REMOVE_UNREAD_MESSAGE_URL = HOST + '/remove-unread-message'

export const GROUP_LIST_URL = HOST + '/group'
export const GROUP_USER_URL = HOST + '/group/user/'
export const GROUP_JOIN_URL = HOST + '/group/join/'

export const COS_FILE_URL = process.env.REACT_APP_COS_URL + '/file'
export const COS_URL = process.env.REACT_APP_COS_URL

export const FILE_URL = HOST + '/file'




export const FINANCIAL_PARAM_URL = HOST + 'financial-param/';
export const AUTH_HEADER_KEY = "Authorization";
export const TOKEN_PREFIX = "Bearer ";

