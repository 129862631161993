import React from 'react';
import {
    Avatar,
    Button,
    Image, message,
} from 'antd';
// import { UserOutlined, TeamOutlined } from '@ant-design/icons';
import {UserOutlined} from '@ant-design/icons';

import { connect } from 'react-redux'
import { actions } from '../../../redux/module/panel'
import * as Params from '../../../common/param/Params'
import {axiosGet, axiosPut} from '../../../util/Request';
import moment from "moment";


class SwitchChat extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            chooseUser: {},
            menuType: 1,
            userUnreadNum:{}
        }
    }

    componentDidMount() {
        this.fetchUserListUnread()
        this.fetchUserList();
        setTimeout(()=>{
            this.scrollToBottom()
        },500)
        // this.scrollToBottom()
    }

    /**
     * 获取有未读消息及数量用户列表
     */

    fetchUserListUnread = () =>{
        let data = {
            uuid: localStorage.uuid
        }
        axiosGet(Params.USER_UNREAD_LIST_URL, data)
            .then(response => {
                let unreadUsers = response.data

                let userUnreadNum = {}
                for (var index in unreadUsers) {
                    userUnreadNum[unreadUsers[index].uuid] = unreadUsers[index].unread_num
                }
                this.setState({
                    userUnreadNum:userUnreadNum
                })
            })
    }

    /**
     * 获取好友列表
     */
    fetchUserList = () => {
        this.setState({
            menuType: 1,
        })
        let data = {
            uuid: localStorage.uuid
        }
        axiosGet(Params.USER_LIST_URL, data)
            .then(response => {
                let users = response.data
                let data = []

                let friendUuid = localStorage.getItem('friendUuid')
                // let selectedIndex = 0

                for (var index in users) {
                    let d = {
                        hasUnreadMessage: this.state.userUnreadNum[users[index].uuid] !== undefined ? this.state.userUnreadNum[users[index].uuid] : 0,
                        username: users[index].email,
                        nickname: users[index].nick_name,
                        companyName: users[index].company_name,
                        uuid: users[index].uuid,
                        messageType: 1,
                        avatar: users[index].avatar !== '' ? ( users[index].avatar.indexOf('images/') !== -1 ? Params.COS_URL + "/" + users[index].avatar : Params.COS_FILE_URL + "/" + users[index].avatar) : Params.HOST + "/file/default.png",
                    }

                    if(users[index].uuid === friendUuid){
                        data.unshift(d)
                    }else{
                        data.push(d)
                    }
                }

                this.props.setUserList(data);
                if(friendUuid !== ''){
                    this.chooseUser(data[0]);
                }
                localStorage.removeItem('friendUuid')
            })
    }

    /**
     * 选择用户，获取对应的消息
     * @param {选择的用户} value
     */
    chooseUser = (value) => {
        let chooseUser = {
            toUser: value.uuid,
            toUsername: value.username,
            toNickname: value.nickname,
            companyName: value.companyName,
            messageType: value.messageType,
            avatar: value.avatar,
        }
        this.fetchMessages(chooseUser);
        this.removeUnreadMessageDot(value.uuid);
    }

    /**
     * 获取消息
     */
    fetchMessages = (chooseUser) => {
        const { messageType, toUser, toUsername } = chooseUser
        let uuid = localStorage.uuid
        if (messageType === 2) {
            uuid = toUser
        }
        let data = {
            Uuid: uuid,
            FriendUsername: toUsername,
            MessageType: messageType
        }

        moment.defineLocale('zh-cn', {
            relativeTime: {
                future: '%s内',
                past: '%s前',
                s: '几秒',
                m: '1分钟',
                mm: '%d分钟',
                h: '1小时',
                hh: '%d小时',
                d: '1天',
                dd: '%d天',
                M: '1个月',
                MM: '%d个月',
                y: '1年',
                yy: '%d年'
            },
        });

        axiosGet(Params.MESSAGE_URL, data)
            .then(response => {
                let comments = []
                let data = response.data
                if (null == data) {
                    data = []
                }
                for (var i = 0; i < data.length; i++) {
                    let contentType = data[i].contentType
                    let content = this.getContentByType(contentType, data[i].url, data[i].content)

                    let comment = {
                        author: data[i].fromUsername + '('+ data[i].fromCompanyName +')',
                        avatar: <Avatar src={data[i].avatar !== '' ? (data[i].avatar.indexOf('images/') !== -1 ? Params.COS_URL + "/" + data[i].avatar : Params.COS_FILE_URL + "/" + data[i].avatar) : Params.HOST + "/file/default.png"}/>,
                        content: <div style={{whiteSpace:'pre-wrap'}}>{content}</div>,
                        datetime: moment(data[i].createAt).fromNow(),
                    }
                    comments.push(comment)
                }

                this.props.setMessageList(comments);
                // 设置选择的用户信息时，需要先设置消息列表，防止已经完成了滑动到底部动作后，消息才获取完成，导致消息不能完全滑动到底部
                this.props.setChooseUser(chooseUser);
                setTimeout(()=>{
                    this.scrollToBottom()
                },500)
            });
    }

    /**
     * 发送消息或者接受消息后，滚动到最后
     */
    scrollToBottom = () => {
        let div = document.getElementById("scrollableDiv")
        // console.log('div.clientHeight:'+div.clientHeight)
        // console.log('div.scrollHeight:'+div.scrollHeight)
        div.scrollTop = div.scrollHeight
    }


    openFile = (url,type) => {
        if(type === 2 ){
            window.open(Params.COS_FILE_URL + '/' + url,'__blank')
        }else{
            return false
        }
    }

    /**
     * 根据文件类型渲染对应的标签，比如视频，图片等。
     * @param {文件类型} type
     * @param {文件地址} url
     * @returns
     */
    getContentByType = (type, url, content) => {
        if (type === 2) {
            content = <img src={Params.HOST + "/file/file.jpg"} alt="" width="100px" onClick={() => this.openFile(url,type)} />
        } else if (type === 3) {
            content = <Image src={Params.COS_FILE_URL + "/" + url} alt="" width="150px" preview={{src: Params.COS_FILE_URL + "/" + url}}  />
        } else if (type === 4) {
            content = <audio src={Params.COS_FILE_URL + "/" + url} controls autoPlay={false} preload="auto" />
        } else if (type === 5) {
            content = <video src={Params.COS_FILE_URL + "/" + url} controls autoPlay={false} preload="auto" width='200px' />
        }

        return content;
    }

    /**
     * 查看消息后，去掉未读提醒
     * @param {发送给对应人员的uuid} toUuid
     */
    removeUnreadMessageDot = (toUuid) => {
        let userList = this.props.userList;
        for (var index in userList) {
            if (userList[index].uuid === toUuid) {
                if(userList[index].hasUnreadMessage !== 0){
                    let meUuid = localStorage.getItem('uuid')
                    this.removeUnreadApi(toUuid,meUuid)
                }
                userList[index].hasUnreadMessage = 0;
                this.props.setUserList(userList);
                break;
            }
        }
    }

    /**
     * 移除未读消息
     * @param side 对方uuid （即toUuid）
     * @param me 我的uuid
     */
    removeUnreadApi = (sideUuid,meUuid) => {
        let param = {
            fromUuid:sideUuid,
            toUuid:meUuid
        }
        axiosPut(Params.REMOVE_UNREAD_MESSAGE_URL, param)
            .then(response => {
                if(response.code === -1){
                    message.error(response.msg)
                    return
                }
            })
    }

    /**
     * 获取群组列表
     */
    // fetchGroupList = () => {
    //     this.setState({
    //         menuType: 2,
    //     })
    //     let data = {
    //         uuid: localStorage.uuid
    //     }
    //     axiosGet(Params.GROUP_LIST_URL + "/" + localStorage.uuid, data)
    //         .then(response => {
    //             let users = response.data
    //             let data = []
    //             for (var index in users) {
    //                 let d = {
    //                     username: users[index].name,
    //                     uuid: users[index].uuid,
    //                     messageType: 2,
    //                 }
    //                 data.push(d)
    //             }
    //
    //             this.props.setUserList(data);
    //         })
    // }

    render() {
        const { menuType } = this.state
        return (
            <div style={{marginTop: 25}}>
                <p >
                    <Button
                        icon={<UserOutlined />}
                        size="large"
                        type='link'
                        disabled={menuType === 1}
                        onClick={this.fetchUserList}
                        style={{color: menuType === 1 ? '#1890ff' : 'gray'}}
                    >
                    </Button>
                </p>
                {/*<p onClick={this.fetchGroupList}>*/}
                {/*    <Button*/}
                {/*        icon={<TeamOutlined />}*/}
                {/*        size="large"*/}
                {/*        type='link'*/}
                {/*        disabled={menuType === 2}*/}
                {/*        style={{color: menuType === 2 ? '#1890ff' : 'gray'}}*/}
                {/*    >*/}
                {/*    </Button>*/}
                {/*</p>*/}
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        user: state.userInfoReducer.user,
        chooseUser: state.panelReducer.chooseUser,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setUserList: (data) => dispatch(actions.setUserList(data)),
        setChooseUser: (data) => dispatch(actions.setChooseUser(data)),
        setMessageList: (data) => dispatch(actions.setMessageList(data)),
    }
}

SwitchChat = connect(mapStateToProps, mapDispatchToProps)(SwitchChat)

export default SwitchChat